<template>
  <v-footer color="primary" padless>
    <v-row
      justify="center"
      no-gutters
    >
      <v-col
        class="primary text-center white--text"
        cols="12"
      >
        <!-- {{ new Date().getFullYear() }} © <strong>BKPSDM Bandung </strong> -->
        <small>Copyright © 2020 - {{ new Date().getFullYear() }} BKPSDM Kota Bandung | {{MbVersion}}<!--.{{version_date}}--> . {{MbBuild}}</small>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
  export default {
    name: 'DashboardCoreFooter',
    data: () => ({}),
    computed:{
      version_date() {
        var date = new Date()
        var year = date.getFullYear().toString()
        var month = date.getMonth() < 10 ? '0'+parseInt(date.getMonth()+1) : date.getMonth()
        return year.substr(2, 4) + '' + month + '' + date.getDate()
      },
      MbVersion() {
         //return _g.MbVersion
         return 'Mangbagja 1.3'
      },
      MbBuild() {
         //return _g.getMbBuild() + ' . ' + _g.getMbBuildCount()
         return 'Lrvl/240525' + ' . ' + '#643'
      }
    }
  }
</script>